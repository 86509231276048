@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text&family=Open+Sans:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  background-color: whitesmoke;
  margin: 0;
}

hr {
  border: 0;
  background-color: lightgray;
  height: 5px;
}

.header h1 {
  font-size: 3rem;
  padding: 0;
  margin: .2em;
}

.header {
  margin: 0;
  padding-left: 3em;
  background-color: darkblue;
  color: white;
  font-family: 'DM Serif Text', serif;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header a {
  color: white;
}

.header a:hover,
.header a:focus {
  color: white;
}

.header-category {
  display: inline;
  font-family: 'DM Serif Text', serif;
  color: red;
  font-weight: normal;
}

.header-date {
  font-family: 'Open Sans', sans-serif;
  margin-right: 17%;
  font-size: .8rem;
  color: whitesmoke;
}

.hamburger {
  display: none;
}

.burger {
  width: 2rem;
  height: .25rem;
  border-radius: 10px;
  background-color: black;
  transform-origin: 1px;
  transition: all .3s linear;
}

.navbar {
  height: fit-content;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 3em;
  background-color: white;
}

.navbar-links {
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  flex-wrap: wrap;
}

.navbar-links-hidden {
  display: flex;
}

.nav-link {
  cursor: pointer;
  margin-right: 1em;
  margin-left: 1em;
  border-bottom: 5px solid transparent;
  transition: border .5s;
}

.nav-link:hover,
.nav-link:focus {
  color: darkblue;
  border-bottom: 5px solid red;
}

.active {
  color: darkblue;
  border-bottom: 5px solid red;
}

.container {
  width: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-left: 3em;
  margin-right: 1em;
}

.content {
  margin-top: 1.2em;
  width: 60%;
}

.card {
  margin-bottom: 2em;
  padding: 1em;
  background-color: white;
  line-height: 1.2;
  border-bottom: 5px solid lightgray;
}

.card-header {
  margin-top: 0;
  font-size: 2rem;
  font-family: 'DM Serif Text', serif;
}

.card-subheader {
  font-size: 1rem;
}

.card img {
  width: 100%;
}

.card-author {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1em;
  margin-bottom: 1em;
}

.card-author span {
  font-weight: normal;
  text-align: right;
}

.card-date {
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  color: black;
  font-size: 1rem;
  margin-bottom: 0;
  text-align: right;
}

.card:hover, 
.card:focus {
  color: black;
}

.filter {
  position: relative;
}

.filter:after {
  content: "";
  height: 80%;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(to bottom, transparent, #fff);
}

.card-read {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  font-family: 'DM Serif Text', serif;
}

.card-read a {
  color: darkblue;
  bottom: 0;
}

.card-read a:hover,
.card-read a:focus {
  color: red;
}

.sidebar {
  padding: 1em;
  margin-top: 1.2em;
  background-color: white;
  width: 35%;
  margin-bottom: 2em;
}

.sidebar-header {
  color: darkblue;
  font-family: 'DM Serif Text', serif;
  font-size: 1.8rem;
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.sidebar-subheader {
  margin-top: 0;
  font-size: 1.8rem;
  color: red;
  font-weight: bold;
  font-family: 'DM Serif Text', serif;
}

.scroll-up {
  margin-right: .4em;
  padding-right: .4em;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  position:fixed;
  bottom: 0;
  right: 0;
  font-weight: bold;
  font-size: 1.5rem;
  color: darkblue;
  transition: color .5s;
}

.scroll-up:hover,
.scroll-up:focus {
  color: red;
}

.footer {
  background-color: whitesmoke;
  text-align: center;
}
.footer p {
  font-size: .8rem;
}

a {
  color: black;
  text-decoration: none;
}

a:hover,
a:focus {
  color: darkblue;
}

@media (max-width: 800px){
  .header {
    padding-left: 1em;
  }

  .header h1 {
    margin: .1em;
    font-size: 1.5rem;
  }

  .header-category {
    display: inline;
    font-family: 'DM Serif Text', serif;
    color: red;
    font-weight: bold;
  }

  .header-date {
    margin-right: 1em;
    font-size: .8rem;
  }

  .hamburger {
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-between;
    flex-flow: column nowrap;
    z-index: 10;
  }

  .navbar {
    padding-left: 1em;
  }

  .navbar-links {
    flex-direction: column;
  }

  .nav-link {
    margin-top: 1em;
    width: fit-content;
    border-bottom: 5px solid transparent;
  }

  .navbar-links-hidden {
    display: none;
  }

  .container {
    flex-direction: column;
    align-items: center;
    width: 93%;
    margin: 1em;
  }
  .content {
    width: 100%;
  }
  .sidebar {
    width: 100%;
  }
}